<template>
    <table class="table-container">
        <tr class="table-header">
            <th v-for="column in columnsTitle" :key="column.id">{{ column.name }}</th>
        </tr>
        <tr v-for="sensor in sensors" :key="sensor">
            <td class="row-item">{{ sensor.application ? sensor.application : '' }}</td>
            <td class="row-item">{{ sensor.modbus ? sensor.modbus : '' }}</td>
            <td class="row-item">{{ sensor.temperature_flow || sensor.temperature_flow === 0 ? validationParametrs(sensor.temperature_flow) : '' }}</td>
            <td class="row-item">{{ sensor.temperature_return || sensor.temperature_return === 0 ? validationParametrs(sensor.temperature_return) : '' }}</td>
            <td class="row-item">{{ sensor.energy ? sensor.energy : '' }}</td>
            <td class="row-item">{{ sensor.flow || sensor.flow === 0 ? sensor.flow : '' }}</td>
            <td class="row-item">{{ sensor.set_point || sensor.set_point === 0 ? validationParametrs(sensor.set_point / 100) : '' }}</td>
            <td class="row-item">{{ sensor.relative_point || sensor.relative_point === 0 ? validationParametrs(sensor.relative_point / 100) : '' }}</td>
            <td class="row-item">{{ sensor.error_status || sensor.error_status === 0 ? sensor.error_status : '' }}</td>
        </tr>
    </table>
</template>

<script>
export default {
    name: "Table values",
    props: ['sensors'],
    data() {
        return {
            columnsTitle: [
                { id: 1, name: "App"},
                { id: 2, name: "Modbus"},
                { id: 3, name: "Temp Flow"},
                { id: 4, name: "Temp Return"},
                { id: 5, name: "Energy"},
                { id: 6, name: "Flow"},
                { id: 7, name: "IST-Wert"},
                { id: 8, name: "SOLL-Wert"},
                { id: 9, name: "Error"},
            ]
        }
    },
    methods: {
        validationParametrs(data) {
            if(Number.isInteger(data)) {
                return data.toFixed(1).replace(/\./g, ',');
            } else {
                const num = +(Math.round(data + "e+1") + "e-1");
                return Number.isInteger(num) ? String(num.toFixed(1)).replace(/\./g, ',') : String(num).replace(/\./g, ',');
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.table-container {
    border: none;
    width: 100%;

    .table-header {
        th {
            width: 90px;
            color: #022259;
            font-size: 14px;
        }
    }

    .row-item {
        text-align: center;
        padding: 3px 0;
        color: #022259;
        font-size: 14px;
    }
}

@media screen and (min-width: 1920px) {
    .table-header {
        th {
            font-size: 18px !important;
        }
    }

    .row-item {
        padding: 5px 0 !important;
        font-size: 18px !important;
    }
}

@media screen and (min-width: 2560px) {
    .table-header {
        th {
            font-size: 24px !important;
        }
    }

    .row-item {
        padding: 10px 0 !important;
        font-size: 24px !important;
    }
}

@media screen and (min-width: 3840px) {
    .table-header {
        th {
            width: 150px !important;
            font-size: 36px !important;
        }
    }

    .row-item {
        // padding: 10px 0 !important;
        font-size: 36px !important;
    }
}
</style>