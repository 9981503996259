<template>
    <div class="result-table-wrapper">
        <div class="result-table">
            <div class="result-table-header-wrapper">
                <div class="result-table-title">
                    <p class="table-name"><b>Router / Stränge</b><span class="subtitle"> - Zugeordnete Gruppen</span></p>
                </div>
            </div>
            <div class="result-table-body-wrapper">
                <div class="table-wrapper">
                    <div>
                        <div v-for="device in getLinkedDevices" :key="device._id" class="result-table-form-wrapper">
                            <div class="result-table-form" :class="{'selected-block': device.showDetails, 'selected-device': Object.keys(getCurrentTestingDevice).length !== 0 && device.flat._id === getCurrentTestingDevice.flat._id}">
                                <div class="form-title">
                                    <img v-if="device.status === 1" src="/images/TRB-active-status.png" alt="work status" class="form-status">
                                    <img v-else src="/images/TRB-unactive-status.png" alt="dont work status" class="form-status">
                                    <span class="strang-name">Wohnung {{ device.flat.flat_number}} - {{ device.flat.location }}</span>
                                </div>
                                <span class="trb-name" v-if="!device.showDetails">TRB{{ device.uid }}</span>
                                <span class="form-date" v-if="!device.showDetails">{{ formatDate(device.started_at) }}</span>
                                <div class="form-control">
                                    <div class="form-indicators">
                                        <img v-if="device.self_diagnostic_status === 1" src="/images/done.png" alt="work status" class="icon-wrapper">
                                        <img v-else-if="device.self_diagnostic_status === 2" src="/images/error.png" alt="error status" class="icon-wrapper">
                                        <img v-else src="/images/checkmark.png" alt="no status" class="icon-wrapper">
                                    </div>
                                    <div class="form-button">
                                        <button @click="getTestList(device)" :class="{'rotate-button': device.showDetails, 'rotate-right': !device.showDetails}"></button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="device.showDetails" class="hidden-block" :class="{'selected-device': Object.keys(getCurrentTestingDevice).length !== 0 && device.flat._id === getCurrentTestingDevice.flat._id}">
                                <div class="hidden-block-container">
                                    <div>
                                        <div class="router-table-head">
                                            <div class="router-table-row">
                                                <div class="router-table-column" v-for="field in firstColumns" :key="field">
                                                    <div class="router-column">
                                                        <div class="router-column-title" :class="{'router-column-device-title': field.text === 'Datum & Uhrzeit'}">{{ field.text }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="router-table-body">
                                            <div class="router-table-row">
                                                <div class="router-table-column">
                                                    <span class="left-title">TRB{{ device.uid }}</span>
                                                    <span class="right-title">{{ formatDate(device.started_at) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="second-row-body">
                                            <div class="router-table-row">
                                                <span class="router-table-title">Cloudname</span>
                                                <div class="router-table-block content">
                                                    <span>{{ device.flat.paul_syntax }}</span>
                                                </div>
                                            </div>
                                            <div class="router-table-row details-row">
                                                <span class="router-table-title">Status</span>
                                                <div class="router-table-block">
                                                    <span v-if="device.device_values_status === 1" class="result-table-data">Ok</span>
                                                    <span v-else-if="device.device_values_status === 2" class="result-table-error-data">Fehler</span>
                                                    <span v-else>-</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="device.sensors.length !== 0" class="third-row-body">
                                            <table-values :sensors="device.sensors"/>
                                        </div>
                                        <div class="buttons-wrapper" v-if="!device.is_processing">
                                            <button class="router-button unlink" @click="disconnectDevice(device)">Zuordnung entfernen</button>
                                            <!-- <button class="router-button status-button" @click="getDeviceStatus(device)">Status</button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import validationTimeZone from "../mixins/validationTimeZone.mixin";
import TableValues from "../components//TableValues.vue";
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'Result Table',
  mixins: [validationTimeZone],
  components: {
    TableValues
  },
  data() {
    return {
        firstColumns: [
            { text: 'Seriennummer von Router'},
            { text: 'Datum & Uhrzeit'},
        ],
        secondColumns: [
            { text: ''},
            { text: 'Cloudname'}
        ]
    }
  },
  computed: {
    ...mapGetters(['getLinkedDevices', 'getCurrentTestingDevice', 'getCurrentProperty'])
  },
  methods: {
    ...mapMutations(['setCurrentTestingDevice']),
    formatDate(date) {
        const timeZone = this.validationTimeZone(date);
        return moment(timeZone).format('DD.MM.YYYY HH:mm');
    },
    disconnectDevice(device) {
        const connection = {
            device: device._id,
            flat: device.flat._id,
            token: localStorage.getItem('token')
        }
        this.$socket.emit('devices/unlink', connection);
        this.$socket.emit('flats', {
            token: localStorage.getItem('token'),
            property: this.getCurrentProperty._id
        });
        this.setCurrentTestingDevice({});
    },
    getTestList(device) {
        if(device.showDetails === false) {
            this.setCurrentTestingDevice(device);
        } else {
            this.setCurrentTestingDevice({});
        }
        device.showDetails = !device.showDetails;
    },
    getDeviceStatus(device) {
        this.$socket.emit('devices/status', {
            device: device._id,
            flat: device.flat._id,
            token: localStorage.getItem('token')
        });
    }
  },
}
</script>
<style lang="scss" scoped>

.result-table-wrapper {
    width: 100%;
    height: 100%;
    background: #EFF9FF;
    border-radius: 10px;

    .loading {
        cursor: wait;
    }

    .loading {
        pointer-events: none; 
    }

    .result-table {
        height: calc(100% - 40px);
        padding: 20px;
    }

    .result-table-header-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .result-table-title {
            padding-bottom: 5px;
            border-bottom: 1px solid rgba(2, 34, 89, 0.3);

            .table-name {
                font-size: 18px;
                color: #022259;

                .subtitle {
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }

    .result-table-body-wrapper {
        margin-top: 21px;
        width: 100%;
        height: calc(100% - 40px);

        .table-wrapper {
            overflow-x: auto;
            height: calc(100% - 5px);

            .selected-block {
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-bottom: 1px solid #90A3BD !important;
                grid-template-columns: none !important;
                display: flex !important;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .selected-device {
                border: 1px solid #022259 !important;
            }

            .result-table-form-wrapper {
                margin-top: 10px;
            }

            .hight-temperature {
                border: 1px solid red;
            }

            .result-table-form-wrapper:nth-child(1) {
                margin-top: 0;
            }
        
            .result-table-form {  
                display: grid;
                grid-template-columns: 2.2fr 4fr 1fr 0.8fr;
                grid-column-gap: 5px;
                align-items: center;
                border-radius: 10px;
                background: #FFFFFF;
                height: 30px;
                padding: 5px 10px 5px 10px;

                .form-title {
                    width: auto;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .form-status {
                        margin-bottom: 2px;
                        margin-right: 5px;
                        width: 15px;
                        height: 15px;
                    }

                    .strang-name {
                        font-weight: 500;
                        font-size: 14px;
                        color: #022259;
                    }
                }

                span {
                    font-size: 14px;
                    color: #022259;
                }

                .trb-name {
                    text-align: center;
                }

                .form-date {
                    min-width: 110px;
                    text-align: center;
                }

                .form-control {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 50px;
                    align-items: center;

                    .form-indicators {
                        .icon-wrapper {
                            width: 20px;
                            height: 20px;
                            cursor: pointer;
                        }
                    }

                    button {
                        float: right;
                        width: 20px;
                        height: 20px;
                        background: url('/images/show.png') no-repeat 50% 50%;
                        background-size: contain;
                        border: none;
                    }

                    .rotate-button {
                        transform: rotate(180deg);
                        transition: transform 1s ease;
                    }

                    .rotate-right {
                        transform: rotate(360deg);
                        transition: transform 1s ease;
                    }
                }
            }

            .deactivated-flat {
                background: rgb(230, 230, 230) !important;
            }

            .hight-temperature {
                border: 1px solid red;
            }

            .hidden-block {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                background: #FFFFFF;
                border-top: none !important;
                position: relative;
                height: auto;
                margin-bottom: 10px;

                .hidden-block-container {

                    .warning-block-wrapper {
                        padding: 10px 10px 0 10px;
                    }

                    .warning-block {
                        padding: 10px;
                        background: rgb(255, 202, 202);
                        border-radius: 10px;
                        line-height: 1.2;
                        font-size: 14px;

                        .warning-block-title:first-child {
                            margin-top: 0 !important;
                        }
                        
                        .warning-block-title {
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            img {
                                width: 20px;
                                height: 20px;
                                margin-right: 5px;
                            }

                            span {
                                font-size: 14px;
                            }
                        }
                    }

                    .router-table-head {
                        padding: 10px 10px 9px 10px;

                        .router-table-row {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            
                            .router-table-column {
                                .router-column {
                                    .router-column-title {
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #022259;
                                    }

                                    .router-column-device-title {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }

                    .router-table-body {
                        padding: 0 10px 0 10px;

                        .router-table-row {
                            display: grid;
                            grid-template-columns: 100%;
                            align-items: center;

                            .router-table-column {
                                display: flex;
                                justify-content: space-between;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                background: #FFFFFF;
                                height: 25px;
                                padding: 3px 10px 3px 10px;
                                align-items: center;

                                span {
                                    color: #022259;
                                    font-size: 14px;
                                }

                            }
                        }
                    }

                    .second-row-body {
                        padding: 10px 10px 10px 10px;
                        height: 58px;
                        display: grid;
                        grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
                        grid-column-gap: 10px;

                        .details-row {
                            .router-table-title, span {
                                margin: 0 auto;
                            }
                        }

                        .router-table-row {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .router-table-title {
                                font-size: 14px;
                                font-weight: 500;
                                color: #022259;
                            }

                            .content {
                                padding: 3px 10px 3px 10px !important;
                            }

                            .router-table-block {
                                display: flex;
                                border: 2px solid #022259;
                                border-radius: 7px;
                                background: #FFFFFF;
                                height: 25px;
                                padding: 3px 3px 3px 3px;
                                align-items: center;

                                span {
                                    font-size: 14px;
                                    color: #022259;
                                }

                                .result-table-data {
                                    color: green;
                                }

                                .result-table-error-data{
                                    color: red;
                                }
                            }
                        }
                    }

                    .third-row-body {
                        border: 2px solid #022259;
                        background-color: #FFFFFF;
                        border-radius: 7px;
                        padding: 10px;
                        margin: 0 10px 10px 10px;
                    }

                    .buttons-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 0 10px 10px 10px;

                        button {
                            width: 250px;
                            height: 35px;
                            background: #022259;
                            border: 2px solid #022259;
                            border-radius: 18px;
                            color: #FFFFFF;
                            font-size: 14px;
                            font-weight: 300;
                        }

                        .unlink {
                            margin-right: 10px;
                            width: 34%;
                            background: #022259;
                            color: #FFFFFF;
                        }

                        .unlink:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .testing {
                            width: 40%;
                            background: #022259;
                            color: #FFFFFF;
                            margin-right: 10px;
                        }

                        .testing:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .router-button {
                            border: 2px solid #022259;
                            border-radius: 18px;
                            font-size: 14px;
                        }

                        .status-button {
                            width: 20%;
                        }

                        .status-button:hover {
                            background: #FFFFFF;
                            color: #022259;
                        }

                        .disabled-button {
                            background: #acacac;
                            margin-right: 10px;
                            border-color: #acacac;
                            width: 40%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1260px) {
    .warning-block-wrapper {
        padding: 15px 25px 0 25px !important;
    }

    .result-table-form {
        grid-template-columns: 2.4fr 6fr 2fr 0.5fr !important;
        .form-title {
            .form-status {
                margin-left: 15px !important;
            }
        }
    }

    .form-control {
        width: 70px !important;

        button {
            margin-right: 15px !important;
        }
    }

    .router-table-head {
        padding: 25px 25px 13px 25px !important;
    }

    .router-table-body {
        padding: 0 25px 0 25px !important;
    }

    .second-row-body {
        padding: 25px 25px 25px 25px !important;
    }

    .third-row-body {
        margin: 0 25px 25px 25px !important;
    }

    .buttons-wrapper {
        padding-left: 25px !important;
        padding-bottom: 25px !important;
    }

    .buttons-wrapper {

        button {
            width: 210px !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    .form-title {
        margin-right: 10px !important;
    }

    .form-date {
        min-width: 120px !important;
    }
}

@media screen and (min-width: 1920px) {
    .result-table {
        height: calc(100% - 58px) !important;
        padding: 29px !important;
    }

    .result-table-body-wrapper {
        height: calc(100% - 48px) !important;
        margin-top: 20px !important;
        font-size: 18px !important;
    }

    .result-table-title {
        padding-bottom: 10px !important;

        .table-name {
            font-size: 21px !important;

            .subtitle {
                font-size: 18px !important;
            }
        }
    }

    .result-table-form-wrapper {
        margin-top: 13px !important;
    }

    .result-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .result-table-form {
        height: 50px !important;
        grid-template-columns: 2.4fr 4fr 2fr 1fr !important;

        .form-title {
            .form-status {
                margin-left: 15px !important;
                width: 20px !important;
                height: 20px !important;
            }

            .strang-name {
                font-size: 18px !important;
            }
        }

        span {
            font-size: 18px !important;
            color: #022259;
        }
    }

    .form-control {
        width: 90px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 65px !important;
            align-items: center;

            .icon-wrapper {
                width: 30px !important;
                height: 30px !important;
            }
        }

        button {
            width: 30px !important;
            height: 30px !important;
            margin-right: 15px !important;
        }
    }

    .selected-device {
        border: 2px solid #022259 !important;
    }

    .hight-temperature {
        border: 2px solid red !important;
    }

    .hidden-block {
        font-size: 18px !important;

        .router-table-head {
            padding: 25px 25px 13px 25px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 18px !important;
                        }

                        .router-column-device-title {
                            margin-right: 22px !important;
                        }
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 25px 0 25px !important;

            .router-table-column {
                height: 35px !important;
                
                span {
                    font-size: 18px !important;
                }
            }
        }

        .second-row-body {
            padding: 25px 25px 25px 25px !important;
            height: 76px !important; 
            grid-column-gap: 15px !important;

            .router-table-title {
                font-size: 18px !important;
            }

            .router-table-block {
                height: 35px !important;

                span {
                    font-size: 18px !important;
                }
            }
        }

        .third-row-body {
            margin: 0 25px 25px 25px !important;
        }

        .buttons-wrapper {
            padding-left: 25px !important;
            padding-bottom: 25px !important;

            button {
                font-size: 18px !important;
                height: 45px !important;
                border-radius: 22px !important; 
            }

            .unlink {
                width: 32.3% !important;
            }

            .testing {
                width: 300px !important;
            }

            .disabled-button {
                width: 300px !important;
            }

            .status-button {
                width: 150px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 10px 25px 0 25px !important;
    }

    .warning-block {
        font-size: 18px !important;
        padding: 20px !important;   
        
        .warning-block-title {
            img {
                width: 30px !important;
                height: 30px !important;
                margin-right: 10px !important;
            }

            span {
                font-size: 18px !important;
            }
        }
    }
}

@media screen and (min-width: 2560px) {
    .result-table {
        height: calc(100% - 78px) !important;
        padding: 39px !important;
    }

    .result-table-title {
        .table-name {
            font-size: 36px !important;

            .subtitle {
                font-size: 24px !important;
            }
        }
    }

    .result-table-body-wrapper {
        height: calc(100% - 72px) !important;
        margin-top: 30px !important;
        font-size: 24px !important;
    }

    .result-table-form {
        height: 70px !important;
        grid-template-columns: 2.5fr 4fr 2fr 1fr !important;
        border-radius: 10px !important;

        .form-title {
            .form-status {
                margin-left: 20px !important;
                width: 30px !important;
                height: 30px !important;
                margin-right: 10px !important;
            }

            .strang-name {
                font-size: 24px !important;
            }
        }

        span {
            font-size: 24px !important;
        }
    }

    .result-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .form-control {
        width: 120px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 87px !important;
            align-items: center;

            .icon-wrapper {
                width: 40px !important;
                height: 40px !important;
            }
        }

        button {
            width: 40px !important;
            height: 40px !important;
            margin-right: 20px !important;
        }
    }

    .hidden-block {
        font-size: 24px !important;

        .router-table-head {
            padding: 30px 30px 23px 30px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 24px !important;
                        }

                        .router-column-device-title {
                            margin-right: 24px !important;
                        }
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 30px 0 30px !important;

            .router-table-column {
                height: 50px !important;
                
                span {
                    font-size: 24px !important;
                }
            }
        }

        .second-row-body {
            padding: 30px 30px 30px 30px !important;
            height: 107px !important;
            grid-column-gap: 50px !important;

            .router-table-title {
                font-size: 24px !important;
            }

            .router-table-block {
                height: 50px !important;

                span {
                    font-size: 24px !important;
                }
            }
        }

        .third-row-body {
            margin: 0 30px 30px 30px !important;
        }

        .buttons-wrapper {
            padding-left: 30px !important;
            padding-bottom: 30px !important;

            button {
                font-size: 24px !important;
                height: 60px !important;
                border-radius: 30px !important; 
            }

            .unlink {
                width: 300px !important;
                margin-right: 45px !important;
            }

            .testing {
                width: 350px !important;
                margin-right: 45px !important;
            }

            .disabled-button {
                width: 350px !important;
                margin-right: 45px !important;
            }

            .status-button {
                width: 175px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 20px 30px 0 30px !important;
    }

    .warning-block {
        font-size: 24px !important;
        padding: 18px !important;
        
        .warning-block-title {
            margin-top: 15px !important;
            
            img {
                width: 40px !important;
                height: 40px !important;
                margin-right: 20px !important;
            }

            span {
                font-size: 24px !important;
            }
        }
    }
}

@media screen and (min-width: 3840px) {
    .result-table {
        height: calc(100% - 130px) !important;
        padding: 65px !important;
    }

    .result-table-title {
        .table-name {
            font-size: 48px !important;

            .subtitle {
                font-size: 36px !important;
            }
        }
    }

    .result-table-body-wrapper {
        height: calc(100% - 94px) !important;
        margin-top: 40px !important;
        font-size: 36px !important;
    }

    .result-table-form-wrapper:nth-child(1) {
        margin-top: 0 !important;
    }

    .result-table-form {
        height: 90px !important;
        grid-template-columns: 2.3fr 4fr 2fr 1fr !important;
        border-radius: 10px !important;

        .form-title {
            .form-status {
                margin-left: 35px !important;
                width: 40px !important;
                height: 40px !important;
            }

            .strang-name {
                font-size: 36px !important;
            }
        }

        span {
            font-size: 36px !important;
        }
    }

    .form-control {
        width: 165px !important;

        .form-indicators {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 110px !important;
            align-items: center;

            .icon-wrapper {
                width: 50px !important;
                height: 50px !important;
            }
        }

        button {
            width: 50px !important;
            height: 50px !important;
            margin-right: 35px !important;
        }
    }

    .hidden-block {
        font-size: 36px !important;

        .router-table-head {
            padding: 45px 45px 23px 45px !important;

            .router-table-row {
                .router-table-column {
                    .router-column {
                        .router-column-title {
                            font-size: 36px !important;
                        }

                        .router-column-device-title {
                            margin-right: 30px !important;
                        }
                    }
                }
            }
        }

        .router-table-body {
            padding: 0 45px 0 45px !important;

            .router-table-column {
                height: 60px !important;
                
                span {
                    font-size: 36px !important;
                }
            }
        }

        .second-row-body {
            padding: 45px !important;
            height: 129px !important;
            grid-column-gap: 50px !important;

            .router-table-title {
                font-size: 36px !important;
            }

            .router-table-block {
                height: 60px !important;

                span {
                    font-size: 36px !important;
                }
            }
        }

        .third-row-body {
            margin: 0 45px 45px 45px !important;
        }

        .buttons-wrapper {
            padding-left: 45px !important;
            padding-bottom: 45px !important;

            button {
                font-size: 36px !important;
                height: 70px !important;
                border-radius: 40px !important; 
            }

            .unlink {
                width: 450px !important;
            }

            .testing {
                width: 500px !important;
            }

            .disabled-button {
                width: 500px !important;
            }

            .status-button {
                width: 250px !important;
            }
        }
    }

    .warning-block-wrapper {
        padding: 35px 45px 0 45px !important;
    }

    .warning-block {
        font-size: 36px !important;
        padding: 28px !important;   
        
        .warning-block-title {
            margin-top: 20px !important;

            img {
                width: 50px !important;
                height: 50px !important;
                margin-right: 20px !important;
            }

            span {
                font-size: 36px !important;
            }
        }
    }
}
</style>