<template>
  <div class="home-container">
    <div class="home-blocks">
      <div class="left-home-block">
        <router-strange-table @device="selectedDevice" @flat="selectedFlat" :disableDevice="disableDevice" :disableFlat="disableFlat" class="devices-flats-home-table"></router-strange-table>
        <connection-table :device="device" :flat="flat" @clean-flat="cleanFlatCheckbox" @clean-device="cleanDeviceCheckbox" class="connection-home-table"></connection-table>
      </div>
      <div class="right-home-block">
        <result-table class="result-home-table"></result-table>
      </div>
    </div>
  </div>
</template>

<script>
import RouterStrangeTable from '../../components/RouterStrangeTable.vue';
import ConnectionTable from '../../components/ConnectionTable.vue';
import ResultTable from '../../components/ResultTable.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'Home',
  components: {
    RouterStrangeTable,
    ConnectionTable,
    ResultTable
  },
  data() {
    return {
      device: {},
      flat: {},
      disableFlat: true,
      disableDevice: true,
    }
  },
  methods: {
    ...mapActions(['getAllFlats', 'getAllDevices']),
    ...mapMutations(['addLinkedFlat', 'deleteDevice']),
    selectedDevice(data) {
      let item = 0;
      this.device = { device: data, item: item++ };
    },
    selectedFlat(data) {
      let item = 0;
      this.flat = { flat: data, item: item++ };
    },
    cleanFlatCheckbox(data) {
      let item = 0;
      this.disableFlat = { flat: data, item: item++ };
    },
    cleanDeviceCheckbox(data) {
      let item = 0;
      this.disableDevice = { device: data, item: item++};
    }
  }
}
</script>

<style lang="scss">
.home-container {
  position: absolute;
  margin: 120px 25px 40px 25px;
  width: calc(100% - 50px);
  height: calc(100% - 160px);

  .home-blocks {
    height: 100%;
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    grid-column-gap: 13px;

    .left-home-block {
      display: grid;
      grid-template-rows: calc(100vh - 373px) 200px;
      grid-row-gap: 13px;

      .devices-flats-home-table {
        width: 100%;
      }

      .connection-home-table {
        width: 100%;
      }
    }

    .right-home-block {

      .result-home-table {
        width: 100%;
        height: calc(100vh - 160px);
      }
    }
  }
}

@media screen and (max-width: 1260px) {

  .home-blocks {
    display: flex !important; 
    flex-direction: column;
    height: auto !important;
    .connection-home-table {
      height: 197px;
    }

    .right-home-block {
      margin-top: 13px !important;
      height: 440px;

      .result-home-table {
        height: 400px !important;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .home-container {
    margin: 125px 30px 45px 30px !important;
    width: calc(100% - 60px) !important;
    height: calc(100% - 170px) !important;
  }

  .left-home-block {
    grid-template-rows: calc(100vh - 434px) 252px !important;
  }

  .result-home-table {
    height: calc(100vh - 170px) !important;
  }
}

@media screen and (min-width: 2560px) {
  .home-container {
    margin: 165px 40px 60px 40px !important;
    width: calc(100% - 80px) !important;
    height: calc(100% - 225px) !important;
  }

  .home-blocks {
    grid-column-gap: 29px !important;
  }

  .left-home-block {
    grid-template-rows: calc(100vh - 606px) 352px !important;
    grid-row-gap: 29px !important;
  }

  .result-home-table {
    height: calc(100vh - 225px) !important;
  }
}

@media screen and (min-width: 3840px) {
  .home-container {
    margin: 235px 50px 60px 50px !important;
    width: calc(100% - 100px) !important;
    height: calc(100% - 295px) !important;
  }

  .home-blocks {
    grid-column-gap: 29px !important;
  }

  .left-home-block {
    grid-template-rows: calc(100vh - 795px) 471px !important;
    grid-column-gap: 29px !important;
  }

  .result-home-table {
    height: calc(100vh - 295px) !important;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
